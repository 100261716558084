import { ComponentType, ForwardedRef, forwardRef } from 'react'

export type GenericForward<T = unknown> = ComponentType<T>

// Want to know more?
// Please check ADR file: 0009-forward-ref-wrapper.md
export function forwardRefWrapper<CPROP>(Component: GenericForward<CPROP>) {
  const component = (props: CPROP, ref: ForwardedRef<HTMLElement>) => (
    <Component {...props} forwardedRef={ref} />
  )

  component.displayName = Component.displayName || Component.name

  return forwardRef(component)
}
