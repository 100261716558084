import styled, { css } from 'styled-components'

import { FormFieldValidation } from '@/lib/core/interfaces/forms'
import { ValidationBadgeProps } from '@/lib/core/components/ValidationBadge'

export type WrapperProps = {
  $placement: ValidationBadgeProps['placement']
  $status: FormFieldValidation
}

const colorsStyles = ({ $status }: WrapperProps) => {
  switch ($status) {
    case FormFieldValidation.INVALID:
      return css`
        background: ${({ theme: { colors } }) => colors.backgroundError};
        color: ${({ theme: { colors } }) => colors.error};
      `
    default:
      return css`
        background: ${({ theme: { colors } }) => colors.backgroundActive};
        color: ${({ theme: { colors } }) => colors.active};
      `
  }
}

const positioningStyles = ({ $placement }: WrapperProps) =>
  $placement &&
  css`
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(10px, -50%);
  `

export const Wrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme: { layout } }) => layout.spacing(4)};
  height: ${({ theme: { layout } }) => layout.spacing(4)};
  border-radius: 50%;
  ${colorsStyles}
  ${positioningStyles}
`
