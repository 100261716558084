import { useTheme } from 'styled-components'
import React, { ReactNode } from 'react'

import { MergeElementProps } from '@/lib/core/interfaces/common'
import { Loader } from '@/lib/core/components/Loader'

import { StyledButton, SvgWrapper } from './Button.styles'

export const BUTTON_VARIANTS = ['primary', 'secondary', 'tertiary', 'ghost', 'text'] as const
export type ButtonVariants = (typeof BUTTON_VARIANTS)[number]

export const BUTTON_SIZES = ['large', 'medium', 'small'] as const
export type ButtonSizes = (typeof BUTTON_SIZES)[number]

export type StyledButtonProps = {
  active?: boolean
  disabled?: boolean
  fitToContent?: boolean
  isLoading?: boolean
  leftIcon?: ReactNode
  lightVariant?: boolean
  minWidth?: boolean
  rightIcon?: ReactNode
  size?: ButtonSizes
  variant?: ButtonVariants
}

export type ButtonProps = MergeElementProps<'button', StyledButtonProps>

export const Button = ({
  children,
  isLoading,
  leftIcon,
  minWidth = false,
  onClick,
  rightIcon,
  size = 'medium',
  type = 'button',
  variant = 'primary',
  ...props
}: ButtonProps) => {
  const { buttons } = useTheme()

  return (
    <StyledButton
      onClick={onClick}
      type={type}
      variant={variant}
      size={size}
      isLoading={isLoading}
      minWidth={minWidth}
      leftIcon={!!leftIcon}
      rightIcon={!!rightIcon}
      {...props}
    >
      {isLoading ? (
        <SvgWrapper size={size}>
          <Loader color={buttons[variant].color} />
        </SvgWrapper>
      ) : (
        <>
          {leftIcon && <SvgWrapper size={size}>{leftIcon}</SvgWrapper>}
          {children}
          {rightIcon && <SvgWrapper size={size}>{rightIcon}</SvgWrapper>}
        </>
      )}
    </StyledButton>
  )
}
