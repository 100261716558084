export enum FormFieldValidation {
  IDLE = 'idle',
  VALIDATING = 'validating',
  VALID = 'valid',
  INVALID = 'invalid',
}

/*
  MakeFormState generic type allows to:
    - defining form state type based on payload type to keep a single source of truth (1st param)
    - using null values in the form
    - splitting the request payload type into form steps (2nd param - optional)
    - overriding (3rd param - optional) single properties types to custom types (like SelectOption)
*/
export type MakeFormState<
  Payload,
  Picks extends keyof Payload = keyof Payload,
  Overrides extends { [K in Picks]?: unknown | never } = never,
> = {
  [K in Picks]: keyof Overrides extends K ? Overrides[K] : Payload[K] | null
}
