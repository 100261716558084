import styled, { css } from 'styled-components'

import { InfoboxProps } from './Infobox'

type WrapperProps = {
  $flexDirection: InfoboxProps['flexDirection']
  $variant: InfoboxProps['variant']
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'row'};
  justify-content: center;
  align-items: center;
  min-height: ${({ theme: { layout } }) => layout.spacing(7)};
  margin: ${({ theme: { layout } }) => layout.spacing(2)} 0;
  border-radius: ${({ theme: { border } }) => border.radius};
  padding: ${({ theme: { layout } }) => `${layout.spacing(1)} ${layout.spacing(3)}`};
  text-align: center;
  ${({ $variant }) => {
    switch ($variant) {
      case 'error':
        return css`
          background: ${({ theme: { colors } }) => colors.backgroundError};
        `
      case 'warning':
        return css`
          background: ${({ theme: { colors } }) => colors.backgroundWarning};
        `
      default:
        return css`
          background: ${({ theme: { colors } }) => colors.backgroundInfo};
        `
    }
  }}
`
