import { ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import get from 'lodash-es/get'

import { MergeElementProps } from '@/lib/core/interfaces/common'
import { FormFieldValidation } from '@/lib/core/interfaces/forms'
import { ValidationBadge } from '@/lib/core/components/ValidationBadge'

import {
  FieldError,
  FieldErrorMessage,
  Input,
  InputWrapper,
  Label,
  LeftAdorment,
  RightAdorment,
} from './TextField.styles'

export type TextFieldProps = MergeElementProps<
  'input',
  {
    displayInline?: boolean
    label?: string
    leftAdorment?: ReactNode
    name: string
    rightAdorment?: ReactNode
    validation?: FormFieldValidation
  }
>

export const TextField = ({
  displayInline,
  label,
  leftAdorment,
  disabled,
  name,
  rightAdorment,
  type,
  validation = FormFieldValidation.IDLE,
  ...props
}: TextFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const error = get(errors, `${name}.message`)
  const isValidating = validation === FormFieldValidation.VALIDATING
  const isBadgeActive = validation !== FormFieldValidation.IDLE
  const isDisabled = disabled || isValidating

  return (
    <>
      <Label
        $required={!!label && props.required}
        $displayInline={displayInline}
        $disabled={disabled}
        htmlFor={name}
      >
        {label}
      </Label>
      <InputWrapper $disabled={isDisabled}>
        {leftAdorment && <LeftAdorment>{leftAdorment}</LeftAdorment>}
        <Input
          id={name}
          type={type}
          $hasError={!!error}
          disabled={isDisabled}
          $leftAdorment={!!leftAdorment}
          $rightAdorment={!!rightAdorment}
          {...register(name)}
          {...props}
        />
        {rightAdorment && <RightAdorment>{rightAdorment}</RightAdorment>}
        {isBadgeActive && <ValidationBadge placement="formControl" status={validation} />}
      </InputWrapper>
      <FieldError>
        {error && (
          <FieldErrorMessage $displayInline={displayInline} $isValidating={isBadgeActive}>
            {error?.message?.toString()}
          </FieldErrorMessage>
        )}
      </FieldError>
    </>
  )
}
