import { ReactNode } from 'react'

import { Wrapper } from './Infobox.styles'

export type InfoboxProps = {
  children?: ReactNode
  flexDirection?: 'column' | 'row'
  variant?: 'info' | 'error' | 'warning'
}

export const Infobox = ({ children, flexDirection = 'column', variant = 'info' }: InfoboxProps) => (
  <Wrapper $flexDirection={flexDirection} $variant={variant}>
    {children}
  </Wrapper>
)
