import styled, { css } from 'styled-components'
import Link from 'next/link'

import { AnchorButtonProps } from './AnchorButton'
import { ButtonProps, StyledButtonProps } from './Button'

const duration = '0.33s'
const timing = 'ease-in-out'

const variantStateStyles =
  (state: 'hover' | 'disabled') =>
  ({ variant = 'primary' }: StyledButtonProps) => css`
    background-color: ${({ theme: { buttons } }) => variant && buttons[variant][state].background};
    color: ${({ theme: { buttons } }) => variant && buttons[variant][state].color};
    ${({ theme: { buttons } }) =>
      variant &&
      buttons[variant][state].borderColor &&
      css`
        border-color: ${buttons[variant][state].borderColor};
      `};
    ${state === 'disabled' &&
    css`
      cursor: not-allowed;
    `};
  `

const variantStyles = ({ variant = 'primary', active, disabled }: StyledButtonProps) => css`
  background-color: ${({ theme: { buttons } }) => variant && buttons[variant].background};
  color: ${({ theme: { buttons } }) => variant && buttons[variant].color};
  ${({ theme: { buttons } }) =>
    variant &&
    buttons[variant].borderColor &&
    css`
      border-color: ${buttons[variant].borderColor};
    `};

  &:hover {
    ${variantStateStyles('hover')}
  }

  ${active && variantStateStyles('hover')}
  &:disabled {
    ${variantStateStyles('disabled')}
  }

  ${disabled && variantStateStyles('disabled')}
`

const lightVariantStyles = ({ lightVariant, variant }: StyledButtonProps) =>
  lightVariant &&
  variant === 'ghost' &&
  css`
    border-color: #fff;
    color: #fff;
  `

const textLightVariantStyles = ({ lightVariant, variant }: StyledButtonProps) =>
  lightVariant &&
  variant === 'text' &&
  css`
    color: #fff;
  `

const sizeStyles = ({ size }: StyledButtonProps) => {
  switch (size) {
    case 'large':
      return css<StyledButtonProps>`
        min-width: ${({ minWidth }) => (minWidth ? '200px' : 'auto')};
        min-height: ${({ theme: { layout } }) => layout.spacing(7)};
        padding: ${({ theme: { layout } }) => `${layout.spacing(2)} ${layout.spacing(4)}`};
        font-weight: 500;
        line-height: 1.5;
        ${({ leftIcon }) =>
          leftIcon
            ? css`
                padding-left: ${({ theme: { layout } }) => layout.spacing(2)};
                padding-right: ${({ theme: { layout } }) => layout.spacing(3)};
              `
            : null};
        ${({ rightIcon }) =>
          rightIcon
            ? css`
                padding-left: ${({ theme: { layout } }) => layout.spacing(3)};
                padding-right: ${({ theme: { layout } }) => layout.spacing(2)};
              `
            : null};
      `
    case 'small':
      return css<StyledButtonProps>`
        min-width: ${({ minWidth }) => (minWidth ? '120px' : 'auto')};
        min-height: ${({ theme: { layout } }) => layout.spacing(3)};
        border-radius: 5px;
        padding: ${({ theme: { layout } }) => layout.spacing(1)};
        font-weight: 600;
        font-size: 0.5rem;
        line-height: 1;
        text-transform: uppercase;
      `
    case 'medium':
    default:
      return css<StyledButtonProps>`
        min-width: ${({ minWidth }) => (minWidth ? '136px' : 'auto')};
        min-height: 37px;
        padding: ${({ theme: { layout } }) => `${layout.spacing(1)} ${layout.spacing(2)}`};
        font-weight: 400;
        font-size: 0.8333rem;
        line-height: 1.3333;
      `
  }
}

const buttonStyles = css<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme: { layout } }) => layout.spacing(1)};
  width: ${({ fitToContent }) => (fitToContent ? 'fit-content' : '100%')};
  border: 1px solid transparent;
  border-radius: ${({ theme: { border } }) => border.radius};
  font-size: ${({ theme: { fontSize } }) => fontSize.default};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.default};
  transition:
    background-color ${duration} ${timing},
    border-color ${duration} ${timing},
    color ${duration} ${timing};
  cursor: pointer;
  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
    `}
  ${variantStyles}
  ${lightVariantStyles}
  ${textLightVariantStyles}
  ${sizeStyles}
`

export const SvgWrapper = styled.span<Pick<StyledButtonProps, 'size'>>`
  display: block;
  ${({ size }) => {
    switch (size) {
      case 'large':
        return css`
          height: 24px;
        `
      case 'small':
        return css`
          height: 0.5rem;
        `
      case 'medium':
      default:
        return css`
          height: 1rem;
        `
    }
  }};
`

export const StyledButton = styled.button<ButtonProps>`
  ${buttonStyles}
`

export const StyledAnchor = styled(Link)<AnchorButtonProps>`
  ${buttonStyles}
`

export type IconButtonProps = {
  $variant?: 'primary' | 'secondary' | 'danger'
}

export const IconButton = styled.button<IconButtonProps>`
  border: none;
  padding: 0;
  background-color: transparent;
  font-size: ${({ theme: { fontSize } }) => fontSize.default};
  line-height: 1;
  cursor: pointer;
  ${({ $variant }) => {
    switch ($variant) {
      case 'primary':
        return css`
          color: ${({ theme: { colors } }) => colors.active};
        `
      case 'secondary':
        return css`
          color: ${({ theme: { colors } }) => colors.secondary};
        `
      case 'danger':
        return css`
          color: ${({ theme: { colors } }) => colors.error};
        `
      default:
        return css`
          color: ${({ theme: { colors } }) => colors.text};
        `
    }
  }};

  svg {
    display: block;
  }
`

export const TextButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme: { colors } }) => colors.accent};
  font-family: ${({ theme: { fontFamily } }) => fontFamily.default};
  line-height: 21px;
  text-decoration: underline;
  cursor: pointer;
`
