import { useMemo } from 'react'

import { FormFieldValidation } from '@/lib/core/interfaces/forms'
import { CheckIcon, CloseIcon } from '@/lib/core/components/Icon'
import { Loader } from '@/lib/core/components/Loader'
import { Wrapper } from '@/lib/core/components/ValidationBadge'

export type ValidationBadgeProps = {
  placement?: 'formControl'
  status: FormFieldValidation
}

export const ValidationBadge = ({ placement, status }: ValidationBadgeProps) => {
  const icon = useMemo(
    () => ({
      [FormFieldValidation.IDLE]: null,
      [FormFieldValidation.INVALID]: <CloseIcon />,
      [FormFieldValidation.VALID]: <CheckIcon />,
      [FormFieldValidation.VALIDATING]: <Loader />,
    }),
    [status],
  )

  return (
    <Wrapper $status={status} $placement={placement}>
      {icon[status]}
    </Wrapper>
  )
}
