import styled, { css } from 'styled-components'

import { TextFieldProps } from './TextField'

export type InputProps = TextFieldProps & {
  $hasError?: boolean
  $leftAdorment?: boolean
  $rightAdorment?: boolean
}

const leftAdormentStyles = ({ $leftAdorment }: InputProps) =>
  $leftAdorment &&
  css`
    padding-left: ${({ theme: { layout } }) => layout.spacing(6)};
  `

const rightAdormentStyles = ({ $rightAdorment }: InputProps) =>
  $rightAdorment &&
  css`
    padding-right: ${({ theme: { layout } }) => layout.spacing(6)};
  `

const errorStyles = ({ $hasError }: InputProps) =>
  $hasError &&
  css`
    border-color: ${({ theme: { colors } }) => colors.error};

    &:focus,
    &:focus-within {
      border-color: ${({ theme: { colors } }) => colors.error};
    }
  `

const disabledStyles = ({ disabled }: InputProps) =>
  disabled &&
  css`
    border-color: ${({ theme: { colors } }) => colors.disabled};
    background: #fff;
    color: ${({ theme: { colors } }) => colors.disabled};
  `

export type LabelProps = {
  $disabled?: boolean
  $displayInline?: boolean
  $required?: boolean
}

export const Label = styled.label<LabelProps>`
  display: block;
  line-height: 2;
  cursor: pointer;
  ${({ $displayInline }) =>
    !$displayInline &&
    css`
      margin-bottom: ${({ theme: { layout } }) => layout.spacing(1)};
    `}
  ${({ $required }) =>
    $required &&
    css`
      &::after {
        content: ' *';
      }
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme: { colors } }) => colors.disabled};
    `}
`

export type InputWrapperProps = {
  $disabled?: boolean
}

export const InputWrapper = styled.div`
  position: relative;
  ${({ $disabled }: InputWrapperProps) =>
    $disabled &&
    css`
      color: ${({ theme: { colors } }) => colors.disabled};
    `}
`

export const Input = styled.input<InputProps>`
  width: 100%;
  height: ${({ theme: { layout } }) => layout.spacing(7)};
  border: 1px solid ${({ theme: { border } }) => border.color};
  border-radius: ${({ theme: { border } }) => border.radius};
  padding: ${({ theme: { layout } }) => layout.spacing(2)};
  color: ${({ theme: { colors } }) => colors.text};
  font-weight: 400;
  font-size: 1rem;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.default};
  ${rightAdormentStyles}
  ${leftAdormentStyles}
  ${disabledStyles}

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.disabled};
  }

  &:focus {
    border-color: ${({ theme: { colors } }) => colors.active};
  }

  &:focus-visible {
    outline: none;
  }
  ${errorStyles}
`

export const LeftAdorment = styled.div`
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
`

export const RightAdorment = styled(LeftAdorment)`
  right: 1px;
  left: auto;
`

export const FieldError = styled.div`
  text-align: left;
`

export type FieldErrorMessageProps = {
  $displayInline?: boolean
  $isValidating?: boolean
}

export const FieldErrorMessage = styled.div<FieldErrorMessageProps>`
  color: ${({ theme: { colors } }) => colors.error};
  font-size: ${({ theme: { fontSize } }) => fontSize.small};
  line-height: 1.6;
  ${({ $displayInline }) =>
    !$displayInline &&
    css`
      margin-top: ${({ theme: { layout } }) => layout.spacing(1)};
    `}
  ${({ $isValidating }) =>
    $isValidating &&
    css`
      margin-left: ${({ theme: { layout } }) => layout.spacing(4)};
    `}
`
