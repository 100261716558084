import { defineMessages } from 'react-intl'

export const formsMessages = defineMessages({
  disabledOption: {
    defaultMessage: 'Selected option is disabled',
    description: 'disabled option error message for form select element',
  },
  emailOrNumberId: {
    defaultMessage: 'The value must be a valid email or ID number',
    description: 'for custom contact validation',
  },
  emailPlaceholder: {
    defaultMessage: 'E-mail',
    description: 'form email input placeholder',
  },
  emailValidation: {
    defaultMessage: 'The value must be a valid email',
    description: 'email field validation message',
  },
  formHasErrors: {
    defaultMessage: 'Adjust form fields and try again',
    description: 'add asset form | custom error text in additional window',
  },
  login: {
    defaultMessage: 'Login',
    description: 'form login button',
  },
  min8CharsValidations: {
    defaultMessage: 'Must be at least 8 characters',
    description: 'required min 8 characters validation message',
  },
  numberValidation: {
    defaultMessage: 'The value must be a number',
    description: 'number field validation message',
  },
  passwordPlaceholder: {
    defaultMessage: 'Password',
    description: 'form password input placeholder',
  },
  repeatPasswordPlaceholder: {
    defaultMessage: 'Repeat Password',
    description: 'form repeat password input placeholder',
  },
  repeatPasswordValidation: {
    defaultMessage: 'Passwords must match',
    description: 'required the same password validation message',
  },
  requiredValidation: {
    defaultMessage: 'This field is required',
    description: 'required field validation message',
  },
  submit: {
    defaultMessage: 'Submit',
    description: 'form submit button',
  },
})
